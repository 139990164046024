<template>
    <NuxtLayout name="catalog">

        <!-- top banner -->
        <div class="lg:min-h-[400px] pb-12 lg:pb-24 bg-[#f0f2f5] bg-[url('/img/hp-header.jpg')] bg-no-repeat bg-[25%] xl:bg-right-bottom bg-cover lg:bg-auto">
            <div class="page-container lg:py-12">
                <AtomsTextHeader class="max-w-[450px] header--with-line">Snubní prstýnky</AtomsTextHeader>
                <p class="max-w-[220px] mb-8 text-xl lg:text-gray">Pestrá nabídka snubních prstenů</p>
                <MoleculesButtonsButton :arrowRight="true" href="/snubni-prsteny">Vyberte si</MoleculesButtonsButton>
            </div>
        </div>

        <div class="page-container">

            <!-- usps -->
            <div class="hidden lg:flex justify-center p-8 rounded-md -translate-y-1/2 bg-white shadow-around">
                <div v-for="item in usps" class="flex justify-center items-center flex-1 gap-4">
                    <AtomsImagesIcon :icon="item.icon" size="4xl" class="mr-4" @click="useShopCookie().set('cc', '1')" />
                    <div>
                        {{ item['line-1'] }}
                        <div class="text-gray text-sm">{{ item['line-2'] }}</div>
                    </div>
                </div>
            </div>

            <!-- trend -->
            <TemplatesFeaturedItems />
        </div>

        <!-- measurement hints -->
        <div class="mt-12 mb-4 lg:mt-20 lg:mb-10 pt-4 pb-12 xl:py-20 bg-[#F0F2F5] lg:bg-[url('/img/mereni-velikosti-prstenu.jpg')] bg-cover bg-no-repeat bg-right">
            <div class="page-container sm:bg-[url('/img/test3.png')] lg:bg-none bg-no-repeat bg-right">
                <AtomsTextHeader :type="2" class="header--with-line">{{ $t('Měření velikosti prstenů') }}</AtomsTextHeader>
                <p class="mb-16 max-w-[250px] text-gray">
                    <strong>{{ $t('Jak zjistit velikost prstenu?') }}</strong><br />
                    {{ $t('Objevte osvědčené (i tajné) způsoby měření') }}
                </p>
                <MoleculesButtonsButton :arrowRight="true" href="/magazin/jak-zmerit-velikost-prstenu">{{ $t('Více') }}</MoleculesButtonsButton>
            </div>
        </div>

        <!-- magazine -->
        <div class="page-container">
            <TemplatesNewestMagazineArticles />
        </div>
    </NuxtLayout>
</template>
<script setup>

const usps = JSON.parse(usePage().value.editableBoxes[0].content);

</script>